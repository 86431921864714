<template>
  <div class="flex flex-col w-full h-screen">
    <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
      <Header @onAdd="() => isShow = true" :title="'Contacts'" :count="2" :searchbox="{placeholder: 'Search', icon: 'SearchIcon'}"/>
      <div class="flex justify-between items-center px-4">
        <Text size="sm" color="primary-600" content="View Interview Schedule" decoration="underline" custom-class="cursor-pointer"/>
        <div class="flex items-center space-x-3">
          <Text size="sm" color="primary-600" content="Import Contacts" decoration="underline" custom-class="cursor-pointer"/>
          <Text size="sm" color="primary-600" content="Export as CSV" decoration="underline" custom-class="cursor-pointer"/>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
      <Table :list="contacts"/>
      <CardFooterWithPageButtons  v-bind="pagination" class="px-0 bg-opacity-0"/>
    </div>
  </div>
</template>

<script>
import Header from '../components/molecules/ListViewHeader/WithFilter/ListViewHeader.vue'
import Text from '../components/atoms/Text/Text'
import Table from '../components/organisms/Tables/Simple/Simple.vue'
import CardFooterWithPageButtons from '../components/organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue'


export default {
  components: {
    Header, Text, Table, CardFooterWithPageButtons
  },
  data() {
    return {
      contacts: [
        {
          'Full Name': 'Darrel Phibin',
          'Target Customer': 'Adults in physical',
          'Email': 'darrel@dundermiff.com',
          'Relationship': 'Friend or Family',
          'Status': 'Survey Complete'
        }
      ],
      pagination: {
        totalRecords: 97,
        currentPage: 1,
        perPage: 10,
      }
    }
  }
}
</script>

<style scoped>

</style>
