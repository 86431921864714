<template>
    <div class="flex flex-col p-4">
        <div class="flex items-start lg:items-start justify-between gap-2" >
            <div class="flex items-start lg:items-start flex-1 gap-2 mr-2 mt-1.5 lg:mt-0">
                <Text color="black" weight="bold" size="4xl" :content="title" />
                <Badge v-if="count" :label="count.toString()" px="3" textColor="gray-600" bgColor="gray-300" py="1" isRounded textSize="lg" textWeight="bold"  customClass="" />
            </div>
            <div class="flex gap-2 items-center">
                <InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="searchbox.placeholder" :icon="searchbox.icon" class="hidden lg:block min-w-min"/>
                <ButtonWithIcon :click="() => $emit('onAdd')" :label="btnLabel" iconName="PlusIcon" variant="primary" size="md" class="h-10 lg:h-10" />
            </div>
        </div>
        <InputWithLeadingIcon v-if="isShowSearchBox" :placeholder="searchbox.placeholder" :icon="searchbox.icon" class="block lg:hidden mt-2"/>
        <div class="flex items-center justify-between w-full mt-4 text-gray-500" >
            <div class="flex item-center">
                <Select v-if="filter" class="mr-2" :items="filter.items" :selectedItem="filter.selectedItem" />
                <Select v-if="sort" :items="sort.items" customClass="right-0 w-fit" :selectedItem="sort.selectedItem" />
            </div>
        </div>
    </div>
</template>

<script>
    import Text from "../../../atoms/Text/Text.vue";
    import ButtonWithIcon from "../../Button/WithIcon/WithIcon.vue";
    import Select from "../../Selects/Component/Component.vue";
    import InputWithLeadingIcon from "../../Inputs/InputWithLeadingIcon/InputWithLeadingIcon.vue";
    import Link from "../../../atoms/Link/Link.vue"
    import Badge from '../../../molecules/Badge/Basic/Basic.vue'

    export default {
        components: {
            Text,
            ButtonWithIcon,
            InputWithLeadingIcon,
            Link,
            Select,
            Badge
        },
        name: "ListViewHeader",
        props : {
            title: {
                type: String,
                default: ""
            },
            count: {
                type: Number,
                default: 0
            },
            searchbox: {
                type: Object,
                default: () => {}
            },
            filter: {
                type: Object,
                default: () => {}
            },
            sort: {
                type: Object,
                default: () => {}
            },
            links: {
                type: Object,
                default: () => {}
            },
            isShowSearchBox: {
                type: Boolean,
                default: true
            },
            btnLabel: {
                type: String,
                default: 'Add Contact'
            }
        }

    }
</script>
